import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AppBar from './components/AppBar'
import Footer from './components/Footer'
import { Container, Row, Col } from 'react-bootstrap'
import CountUp from 'react-countup';
import { Buttons } from '../components/Buttons/Buttons';
import { useNavigate } from 'react-router-dom';
import { MagicStar, HeartTick, Quant, Tag2 } from 'iconsax-react';
import Banner from './components/Banner';
import { BsMagic } from "react-icons/bs";
import { IoPricetagOutline,IoDiamondOutline  } from "react-icons/io5";
import API_DOMAIN from '../../src/config/config'
// import Banner from './components/Banner';
import Carousal from './components/Carousal'
const Home = () => {
    const [banner, setbanner] = useState([]);
    useEffect(() => {
        fetch(`${API_DOMAIN}/home_banner.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "search_text": ""
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {

                setbanner(data.body.banner);

            })
            .catch(error => {
                console.error('Error fetching products:', error);
            });


    }, []);
    console.log('banner', banner)
    useEffect(() => {
        AOS.init({
            offset: 100,    // Offset (in px) from the original trigger point
            delay: 500,       // Values from 0 to 3000, with step 50ms
            duration: 3000, // Values from 0 to 3000, with step 50ms
            easing: 'ease', // Default easing for AOS animations
            mirror: true,  // Whether elements should animate out while scrolling past them
        });
    }, []);
    const navigate = useNavigate();
    const handlenavigate = () => {
        navigate('/products');
    }

    return (
        <>
            <div className='over'>
                <AppBar />
                <Banner banner={banner} />

                {/* <Banner/> */}

                {/* <intro start */}
                <div className='padding'>
                    <Container>
                        <Row>
                            <Col lg='12'>
                                <h1 class=" bold text-center pb-5 lg-font h1color"> Welcome to Sri Varathan  Crackers</h1>
                            </Col>
                            <Col lg="6" xs={12} className='py-3 align-self-center'>
                                <div data-aos="fade-right">
                                    <h2 class=" bold pt-5 subheadcolor" style={{ fontSize: "2.5rem" }}>Buy Diwali Crackers at Best Quality & Affordable Price</h2>
                                    <p class="regular">
                                        We have immense pleasure in welcoming you to visit our mega fireworks showroom located in Sivakasi, where you can directly place your valuable orders and fulfill all your crackers requirements at one stop. We are in the cracker's industry for past 10+ years. It's our pride in supplying our esteemed customers with the best quality crackers at the lowest market prices.</p>
                                    <p class="regular">We are the leading supplier of Sparklers, Ground Chakkars, Flower Pots, Fountains, Fancy Crackers, Sound Crackers, Novelty Fireworks, Rockets, Bombs, Twinkling Stars, Elite Crackers, Fancy Deluxe Fountains, Loose Crackers, Electric Crackers, Super Blast Wala Crackers, Fancy Novelties, Multi Colour Shots, Aerial Colour Novelties, Comets and Fireworks Gift Boxes..</p>
                                    <br />
                                    <button class="s-btn" onClick={handlenavigate}>
                                        Shop Now
                                    </button>

                                </div>
                            </Col>
                            <Col lg="6" className='py-3' data-aos="fade-left">
                                <img src={require('../assets/images/home+image.jpg')} className='img-fluid' alt='product name' />
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* <intro end */}
                <div className='padding'>
                    <Container>
                        <Row>
                            <Col lg='6' xs='12'>
                                <div className='text-center'>
                                    <h5 className='bold '>OUR FEATURES</h5>
                                    <h2 className='bold'>Explore Our Shop</h2>
                                </div>
                            </Col>
                            <Col lg='6' xs='12'>
                                <div className='text-center'>
                                    <p className='regular'>We provide public & private display shows for marriages, parties, gala events, product launches, temple festivals and award functions and sports nights. One can buy crackers from us round the year.</p>
                                </div>
                            </Col>
                            <Col lg='4' xs='12' className='my-3'>
                                <div className='feature'>
                                    <div className='feature-icon'>
                        
                                    <IoPricetagOutline />
                                    </div>
                                    <div className='feature-content'>
                                        <h3 className='bold'>Discount Crackers</h3>
                                        <p>Highest Discount at low price</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg='4' xs='12' className='my-3'>
                                <div className='feature'>
                                    <div className='feature-icon'>
                                 
                                    <IoDiamondOutline />
                                    </div>
                                    <div className='feature-content'>
                                        <h3 className='bold'>Wholesale Price</h3>
                                        <p>Our crackers are safe to use</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg='4' xs='12' className='my-3'>
                                <div className='feature'>
                                    <div className='feature-icon'>
                                        <BsMagic /> 
                                    </div>
                                    <div className='feature-content'>
                                        <h3 className='bold'>Best Quality</h3>
                                        <p>100% superior quality crackers</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* why choose start */}
                <div className='padding pattern bg-overlay'>
                    <Container>
                        <Row className='justify-content-center z'>
                            <Col lg='6'>
                                <h1 class=" bold text-center pb-5 lg-font h1color">Why Choose</h1>
                                <p className='regular text-center text-white'>
                                    Sri Varathan Crackers Shop is a top provider of premium crackers and fireworks located in Sivakasi, Tamil Nadu. We offer authentic Sivakasi crackers at highly competitive prices, ensuring you get the best quality for your celebrations.</p>
                            </Col>
                        </Row>
                        <Row className='z'>
                          <Col lg='6' md='12' xs='12'>
                          <div>
                            <img src={require('../assets/images/choose.jpg')} className='img-fluid' alt='diwali best sale' />
                          </div>
                          </Col>
                          <Col lg='6' md='12' xs='12' className='align-self-center'>
                            <Row>
                                <Col lg="6" xs={12} className='pad-z '>
                                    <div data-aos="fade-right" className='why-box-one'>
                                        <div className='choose-icon' data-aos="zoom-in-left">
                                            <Quant
                                                size="32"
                                                color="#fff"
                                            />
                                        </div>
                                        <h5 class=" bold subheadcolor">Quality</h5>
                                        <p class="regular">
                                            Selling Quality Crackers & innovation are the key behind our success.
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="6" xs={12} className='pad-z'>
                                    <div data-aos="fade-down" className='why-box-two'>
                                        <div className='choose-icon' data-aos="zoom-in-left">
                                            <Tag2
                                                size="32"
                                                color="#fff"
                                            />
                                        </div>
                                        <h5 class=" bold subheadcolor">Affordable Price</h5>
                                        <p class="regular">
                                            We are producing safe and Quality crackers with highest quality at low price.
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="6" xs={12} className='pad-z'>
                                    <div data-aos="fade-up" className='why-box-one'>
                                        <div className='choose-icon'>
                                            <MagicStar size="32" color="#fff" />
                                        </div>
                                        <h5 class=" bold subheadcolor">Safe To Use</h5>
                                        <p class="regular">
                                            Crackers we offer are safe and made from fine quality raw materials.
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="6" xs={12} className='pad-z'>
                                    <div data-aos="fade-left" className='why-box-two'>
                                        <div className='choose-icon'>
                                            <HeartTick
                                                size="32"
                                                color="#fff"
                                            />
                                        </div>
                                        <h5 class=" bold subheadcolor">Customer Satisfaction</h5>
                                        <p class="regular">
                                            Our quality and timely delivery has attracted customers easily.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                          </Col>
                        </Row>
                    </Container>
                </div>
                {/* why choose end */}
                {/* products start */}
                <Container className='py-5 padding'>
                    <Row className='justify-content-center'>
                        <Col lg="12" xs={12} className='text-center py-3'>
                            <h2 className='bold h1color'>Our Products</h2>
                            <p className='regular'>Our motto is to make every festival celebration bright and safe.This, we bring out with our wide range of firecrackers. With over 200 varieties of crackers developed and marketed every year, we are among the most sought brands in the Sivakasi region and around the country. Our products are known for their safety and we take great efforts to ensure that all our orders are delivered in a standard time frame with an economical pricing.</p>
                        </Col>
                        <Col lg="4" md="4" xs="12" className='py-3 text-center'>
                            <div class="home-product" data-aos="flip-left">
                                <div>
                                    <img src={require('../assets/images/bombs.jpg')} className='img-fluid' alt='product name' />
                                </div>
                                <div className='home-product-name'>
                                    <h5 className='bold'> One Sound Crackers</h5>
                                    <p className='regular'>Kuruvi,Laxmi,Spider...</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" md="4" xs="12" className='py-3 text-center'>
                            <div class="home-product" data-aos="flip-left">
                                <div>
                                    <img src={require('../assets/images/chakkars.jpg')} className='img-fluid' alt='product name' />
                                </div>
                                <div className='home-product-name'>
                                    <h5 className='bold'>Chakkars</h5>
                                    <p className='regular'>Chakkar Big,Asoka,Deluxe...</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" md="4" xs="12" className='py-3 text-center'>
                            <div class="home-product" data-aos="flip-left">
                                <div>
                                    <img src={require('../assets/images/flowerpots.jpg')} className='img-fluid' alt='product name' />
                                </div>
                                <div className='home-product-name'>
                                    <h5 className='bold'> Flower Pots</h5>
                                    <p className='regular'>Big,Small,Colorkoti...</p>
                                </div>
                            </div>
                        </Col>
                        {/* <Col lg="4" md="4" xs="12"className='py-3 text-center'>
                    <div class="home-product"  data-aos="flip-left">
                       <div>
                            <img src={require('../assets/images/wala.jpg')} className='img-fluid' alt='product name' />
                       </div>
                        <div className='home-product-name'>
                            <h5 className='bold'> Garlands</h5>
                            <p className='regular'>Deluxe,Giant Garlands...</p>
                        </div>
                    </div>
                </Col> */}
                        <Col lg="4" md="4" xs="12" className='py-3 text-center'>
                            <div class="home-product" data-aos="flip-left">
                                <div>
                                    <img src={require('../assets/images/sparklers.jpg')} className='img-fluid' alt='product name' />
                                </div>
                                <div className='home-product-name'>
                                    <h5 className='bold'>Sparklers</h5>
                                    <p className='regular'>Red,Green,Electric...</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" md="4" xs="12" className='py-3 text-center'>
                            <div class="home-product" data-aos="flip-left">
                                <div>
                                    <img src={require('../assets/images/rockets.jpg')} className='img-fluid' alt='product name' />
                                </div>
                                <div className='home-product-name'>
                                    <h5 className='bold'> Rockets</h5>
                                    <p className='regular'>Baby Rockets,Lunik,Whistle...</p>
                                </div>
                            </div>
                        </Col>
                        {/* <Col lg="4" md="4" xs="12"className='py-3 text-center'>
                    <div class="home-product"  data-aos="flip-left">
                       <div>
                            <img src={require('../assets/images/fountain.jpg')} className='img-fluid' alt='product name' />
                       </div>
                        <div className='home-product-name'>
                            <h5 className='bold'> fountain</h5>
                            <p className='regular'>Fun Zone, Photo Flash, Drone...</p>
                        </div>
                    </div>
                </Col> */}
                        <Col lg="4" md="4" xs="12" className='py-3 text-center'>
                            <div class="home-product" data-aos="flip-left">
                                <div>
                                    <img src={require('../assets/images/giftbox.jpg')} className='img-fluid' alt='product name' />
                                </div>
                                <div className='home-product-name'>
                                    <h5 className='bold'> Gift Box</h5>
                                    <p className='regular'>20 Items, 30 Items...</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg="12">
                            <div className='text-center py-3' data-aos="fade-up">
                                <Buttons label={<>View More Products</>} onClick={handlenavigate}></Buttons>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* products end */}


                {/* our special */}
                {/* <Container className='padding'>
                <Row>
                    <Col lg="3" md="6" xs="12" className='py-3'>
                        <div className=' choose text-center py-5' data-aos="zoom-in-right">
                            <div className='choose-icon' data-aos="zoom-in-left">
                            <Quant
                                size="32"
                                color="#555555"
                                />
                            </div>
                                <h2 class="bold">Quality</h2>
                                <p class="regular">Manufacturing Quality Crackers &amp; innovation are the key behind our success.</p>
                        </div>
                    </Col>
                    <Col lg="3" md="6" xs="12" className='py-3'>
                        <div className=' choose text-center py-5' data-aos="zoom-in-right">
                            <div className='choose-icon' >
                            <Tag2
                                size="32"
                                color="#555555"
                                />
                            </div>
                                <h2 class="bold">Affordeble Price</h2>
                                <p class="regular">We are producing safe and compliant crackers with highest quality at low price.</p>
                        </div>
                    </Col>
                    <Col lg="3" md="6" xs="12" className='py-3'>
                        <div className='choose text-center py-5' data-aos="flip-left">
                            <div className='choose-icon'>
                                <MagicStar size="32" color="#555555"/>
                            </div>
                                <h2 class="bold">Safe To Use</h2>
                                <p class="regular">Crackers we offer are safe and made from fine quality raw materials.</p>
                        </div>
                    </Col>
                    <Col lg="3" md="6" xs="12" className='py-3'>
                        <div className='choose text-center py-5' data-aos="flip-right">
                            <div className='choose-icon'>
                                <HeartTick
                                    size="32"
                                    color="#555555"
                                    />
                            </div>
                                <h2 class="bold">Satisfaction</h2>
                                <p class="regular">Our quality and timely delivery has attracted customers easily.</p>
                        </div>
                    </Col>
                </Row>
            </Container> */}
                {/* our special */}
                {/* paralax start */}
                <div className='counter padding bg-overlay z'>
                    <Container>
                        <Row>
                            <Col lg="3" xs={12} className='py-3'>
                                <div className='z'>
                                    <div className='counter-box text-center' >
                                        <CountUp end={15} delay={5} /><span>+</span>
                                    </div>
                                    <div className='text-center bold text-white'>Years Experience</div>
                                </div>
                            </Col>
                            <Col lg="3" xs={12} className='py-3'>
                                <div className='z'>
                                    <div className='counter-box text-center'>
                                        <CountUp end={200} delay={5} /><span>+</span>
                                    </div>
                                    <div className='text-center bold text-white'>Products</div>
                                </div>
                            </Col>
                            <Col lg="3" xs={12} className='py-3'>
                                <div className='z'>
                                    <div className='counter-box text-center'>
                                        <CountUp end={1000} delay={5} /><span>+</span>
                                    </div>
                                    <div className='text-center bold text-white'>Customers</div>
                                </div>
                            </Col>
                            <Col lg="3" xs={12} className='py-3'>
                                <div className='z' >
                                    <div className='counter-box text-center'>
                                        <CountUp end={100} delay={5} /><span>%</span>
                                    </div>
                                    <div className='text-center bold text-white'>Customer Satisfaction</div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* paralax end */}
                <img src={require('../assets/images/center.jpg')} className='img-fluid w-100' alt='product name' data-aos="flip-right" />
                {/* product banner start */}
                <div className='padding'>
                    <Container>
                        <Row>
                            <Col lg="6" className='py-3'>
                                <img src={require('../assets/images/retail.png')} className='img-fluid' alt='product name' data-aos="flip-right" />
                            </Col>
                            <Col lg="6" className='py-3'>
                                <img src={require('../assets/images/wholesale.png')} className='img-fluid' alt='product name' data-aos="flip-left" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* product banner end */}
                {/* sale bg start*/}
                <div className='padding'>
                    <Container>
                        <Row>
                            <Col lg="4" md="12" xs='12' className='py-3 align-self-center'>
                                <div class="" data-aos="fade-right">
                                    <h1 className='bold h1color'> Our Brands</h1>
                                </div>
                            </Col>
                            <Col lg="8" md="12" xs='12' className='py-3 align-self-center text-center'>
                                <Carousal />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='salebg'>
                    <div className='sale'>
                        <Container>
                            <Row>
                                <Col lg="7" md="12" xs='12' className='py-3'>
                                    <div class="" data-aos="fade-right">
                                        <h1 class="bold h1color">
                                            Sri Varathan  Crackers
                                        </h1>
                                        <div className='regular'>
                                            <p>We are the leading crackers wholesale manufacturers in Sivakasi, We deliver crackers directly from Sivakasi at affordable price.</p>
                                            <p>We have a crackers warehouse where you can get 365 days at an affordable price with the best discount.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="5" md="12" xs='12' className='py-3 align-self-center text-center'>
                                    <Buttons label={<>Shop Now . . . !</>} onClick={handlenavigate} data-aos="fade-up" />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                {/* sale bg end*/}
                <Footer />
            </div>
        </>
    )
}

export default Home